<template>
  <div>
    <HeroInner descrition="Gestão de Meta" class="mt-70" />

    <div class="grid-box">
      <v-layout bg-white ma-4 mt-5 column br-4>
        <div class="my-donations-header">
          <div>
            <Icon name="fal fa-user-tie title-icon" size="32" colorFont="#C3C6D5" />
            <h2>Gestão de Meta</h2>
          </div>
          <div>
            <InputText type="search" place-holder="Buscar..." :showLoader="false" v-model="search" />
          </div>
        </div>
      </v-layout>
      <template v-for="project in filteredProjects">
        <div class="bg-white br-4 ma-4" :key="project.id">
          <v-btn @click="redirectToAccountability(project)" class="f-right mr-4 mt-4">
            <v-icon size="16" class="mr-2">fal fa-external-link-square</v-icon>Prestação de contas
          </v-btn>
          <h5 class="px-3 ml-3 pt-4 text-gray-dark">{{ project.institution.entity.name }}</h5>
          <h3 class="px-3 pt-1 pb-3" style="border-bottom:1px solid #e1e1e1">
            <v-icon size="8" class="mr-1 mb-2 text-primary">fas fa-circle</v-icon>

            {{ project.shortName }}
          </h3>
          <v-data-table
            width="100%"
            v-model="selected"
            :headers="headers"
            :items="project.goalList"
            locale="pt_BR"
            :expand="true"
            :disable-pagination="true"
            select-all
            hide-actions
            hide-default-footer="true"
            item-key="id"
            class="text-xs-left"
          >
            <template v-slot:headers>
              <!-- <tr>
                            <th v-for="header in props.headers" :key="header.text">&nbsp;</th>
              </tr>-->
            </template>
            <template v-slot:items="props">
              <tr>
                <td width="20px" style="padding:0">
                  <v-btn icon @click="props.expanded = !props.expanded">
                    <v-icon size="16">{{ props.expanded ? 'fal fa-minus' : 'fal fa-plus' }}</v-icon>
                  </v-btn>
                </td>
                <td>
                  <span class="text-gray-dark">Nome:</span>
                  <b class="nowrap">{{ props.item.name }}</b>
                </td>
                <td>
                  <span class="text-gray-dark nowrap">Verificação por:</span>
                  <b class="nowrap">{{ props.item.goalVerification.name }}</b>
                </td>
                <td>
                  <span class="text-gray-dark nowrap">Valor da Meta:</span>
                  <b class="nowrap">{{ props.item.amount }}</b>
                </td>
                <td>
                  <span class="text-gray-dark">Frequência:</span>
                  <b class="nowrap">{{ GetGoalFrequencyName(props.item.frequency) }}</b>
                </td>
                <td>
                  <span class="text-gray-dark">Tipo:</span>
                  <b class="nowrap">{{ props.item.goalType.name }}</b>
                </td>
                <td width="20px">
                  <v-btn
                    icon
                    small
                    title="Cobrar Prestação de Contas"
                    v-if="showSendEmail(project)"
                    @click="openConfirmModal(props.item)"
                  >
                    <v-icon size="18">fal fa-envelope-open-dollar</v-icon>&nbsp;!
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:expand="props">
              <table class="simple-table no-shadow">
                <thead>
                  <tr>
                    <th width="150px">Período</th>
                    <th width="180px" style="text-align:right">Data</th>
                    <th>Objetivo</th>
                    <th>Realizado</th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="goalValue in props.item.goalValueList" :key="goalValue.id">
                    <td>{{ goalValue.frequencyDesc }}</td>
                    <td style="text-align:right;padding:16px 24px">
                      <span class="text-gray-dark">Início:</span>
                      {{ goalValue.initialDate | FormatDate("L") }}
                      <br />
                      <span class="text-gray-dark">Fim:</span>
                      {{ goalValue.finalDate | FormatDate("L") }}
                    </td>
                    <td>{{ goalValue.value }}</td>
                    <td>{{ goalValue.realized }}</td>
                    <td>{{ goalValue.canceled ? 'Cancelado' : GetGoalsValuesDescription(goalValue.statusId) }}</td>
                    <td width="20px">
                      <v-btn
                        icon
                        title="Prestar Contas"
                        v-if="!goalValue.canceled"
                        @click="openAccountabilityModal(props.item, goalValue)"
                      >
                        <v-icon size="16">fal fa-hands-usd</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </v-data-table>
        </div>
      </template>
    </div>
    <ModalAccountability
      :indexFrequency="indexFrequency"
      @save="loadProjects()"
      ref="modalAccountability"
    />
    <!-- <ModalAccountabilityPhotos
      :indexFrequency="indexFrequency"
      @save="loadProjects()"
      ref="modalAccountabilityPhotos"
    />-->
    <AucAlert
      ref="alertComponent"
      title="Você tem certeza que deseja enviar um email para o gestor da instituição, informando sobre o atraso na comprovação da meta?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="sendEmail()"
    />
  </div>
</template>
<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import HeroInner from "@/components/HeroInner.vue";
import ModalAccountability from "@/views/app/project/modal/ModalAccountability.vue";
import ModalAccountabilityPhotos from "@/views/app/project/modal/ModalAccountabilityPhotos.vue";
import CompanyGroupService from "@/scripts/services/companyGroup.service";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import EntityService from "@/scripts/services/entity.service";
import ProjectService from "@/scripts/services/project.service";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { GetGoalFrequencyName } from "@/scripts/models/enums/goalFrequency.enum";
import {
  GetGoalsValuesDescription,
  GoalsValuesStatus
} from "@/scripts/models/enums/goalsValuesStatus.enum";

export default {
  components: {
    AucAlert,
    HeroInner,
    ModalAccountability,
    ModalAccountabilityPhotos
  },
  data: () => ({
    pagination: {
      sortBy: "fullName"
    },
    search: "",
    selected: [],
    expand: false,
    selectedproject: null,
    headers: [
      { text: "Nome", value: "name", align: "left" },
      { text: "Verificação da meta", value: "fullName", align: "left" },
      { text: "Meta", value: "amount", align: "left" },
      { text: "Frequência", value: "fullName", align: "left" },
      { text: "Tipo", value: "fullName", align: "left" },
      { text: "Ações", value: "actions" }
    ],
    companies: [],
    companyGroupService: new CompanyGroupService(),
    entityService: new EntityService(),
    projectService: new ProjectService(),
    projects: [],
    goals: [],
    GetGoalFrequencyName: null,
    GetGoalsValuesDescription: null
  }),
  created() {
    this.loadProjects();
    this.GetGoalFrequencyName = GetGoalFrequencyName;
    this.GetGoalsValuesDescription = GetGoalsValuesDescription;
  },
  computed: {
    filteredProjects() {
      let searchLower = this.search.toLowerCase();
      return this.projects.filter(function(project) {
        return project.shortName.toLowerCase().indexOf(searchLower) !== -1;
      });
    }
  },
  methods: {
    loadProjects() {
      this.projectService.listAllWithGoals().then(data => {
        this.projects = data.filter(x => x.goalList.length > 0);

        for (let i = 0; i < this.projects.length; i++) {
          for (let j = 0; j < this.projects[i].goalList.length; j++) {
            let frequency = this.projects[i].goalList[j].frequency;
            let monthsCount = 1;
            this.projects[i].goalList[j].goalValueList = this.projects[
              i
            ].goalList[j].goalValueList.map(v => {
              let frequencyDesc = "-";
              if (!v.canceled) {
                frequencyDesc = ` ${monthsCount}º ${GetGoalFrequencyName(
                  frequency
                )}`;
                monthsCount++;
              }
              return { ...v, frequencyDesc };
            });
          }
        }
      });
    },
    editCompany(company) {
      this.$router.push({
        name: Routes.app.CompanyProfile,
        query: { companyId: company.id }
      });
    },
    setEntityStatus(entity, entityStatusId) {
      this.entityService.setEntityStatus(entity.id, entityStatusId).then(
        function(data) {
          if (data.success) {
            entity.entityStatusId = entityStatusId;
          }
        }.bind(this)
      );
    },
    customFilter(projects, search) {
      search = search.toLowerCase();
      return projects.filter(function(project) {
        return (
          project.shortName.toLowerCase().indexOf(search) !== -1 ||
          project.goalList.some(function(goal) {
            return goal.name.toLowerCase().indexOf(search) !== -1;
          })
        );
      });
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    redirectToAccountability(project) {
      this.$router.push({
        name: Routes.app.ProjectAccountability,
        query: { projectId: project.id }
      });
    },
    showDetails(props) {
      props.expanded = !props.expanded;
    },
    openAccountabilityModal(goal, goalValue) {
      this.$refs.modalAccountability.open(goal, goalValue);
    },
    showSendEmail(project) {
      let show = false;
      project.goalList.forEach(goal => {
        goal.goalValueList.forEach(goalValue => {
          if (goalValue.statusId == GoalsValuesStatus.Late) show = true;
        });
      });
      return show;
    },
    openConfirmModal(project) {
      this.selectedproject = project;
      this.$refs.alertComponent.open();
    },
    sendEmail() {
      this.projectService.sendEmailGoalLate(this.selectedproject.id);
    }
  }
};
</script>

<style>
</style> 