<template>
  <div>
    <Modal
      modalTitle="Prestação de Contas - Upload"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="640px"
      :modalFooter="true"
      v-model="show"
    >
      <!-- body  -->
      <template>
        <v-container fluid pa-0>
          <v-layout px-4 py-3 row wrap style="align-items:center;">
            <v-flex>
              <InputMoney
                ref="realizedInput"
                helpLabel="Somente números"
                textLabel="Realizado"
                place-holder="Valor Realizado"
                v-model="goalValue.realized"
                :precision="0"
                prefix
              />
            </v-flex>
            <div class="d-flex xs2 align-end px-3">
              <v-btn
                flat
                round
                color="white"
                class="btn-primary px-5 ml-auto mr-0"
                large
                @click="save()"
              >Salvar</v-btn>
            </div>
          </v-layout>
          <div class="section image-grid" style="padding: 0;">
            <div style="position:relative">
              <div class="wrapper" style="margin:0px;justify-content: flex-start;">
                <div
                  class="item"
                  style="max-height:100px;max-width:110px;min-height:100px;min-width:110px;"
                  v-for="(goalValueFile, index) in goalValue.goalsValuesFiles"
                  :key="goalValueFile.id"
                >
                  <div class="tools">
                    <a @click="removeFile(index)" class="ml-auto" title="Remover Imagem">
                      <i class="fal fa-trash"></i>
                    </a>
                  </div>
                  <FileImage :file="goalValueFile.file" />
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </template>

      <!-- action buttons  -->
      <template v-slot:footer>
        <div class="align-center">
          <div class="upload-bar-gallery w-100 pr-2">
            <label :for="'upload-file'" class="ml-auto mr-4">
              <i class="fal fa-cloud-upload"></i>
            </label>
            <input
              type="file"
              :id="'upload-file'"
              accept=".jpg, .jpeg, .png"
              @change="fileChanged($event)"
            />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import FileImage from "@/components/FileImage.vue";
import GoalsValues from "@/scripts/models/goalsValues.model";
import GoalsValuesFile from "@/scripts/models/goalsValuesFile.model";
import File from "@/scripts/models/file.model";
import ProjectService from "@/scripts/services/project.service.js";
export default {
  components: {
    Modal,
    FileImage
  },
  props: {
    indexFrequency: 0
  },
  data() {
    return {
      searchText: "",
      show: false,
      realized: "",
      goalValue: new GoalsValues(),
      projectService: new ProjectService()
    };
  },
  computed: {},
  methods: {
    open(goalValue) {
      this.copy(goalValue);
      this.loadGoalValue(goalValue.id);
      this.show = true;
    },
    close() {
      this.show = false;
    },
    fileChanged(event) {
      var file = event.target.files[0];
      let newFile = new File();
      newFile.name = file.name;
      let reader = new FileReader();
      reader.onload = function() {
        newFile.path = reader.result;
        newFile.imageContent = reader.result;
      }.bind(this);
      reader.readAsDataURL(file);
      var goalsValuesFile = new GoalsValuesFile();
      goalsValuesFile.file = newFile;
      goalsValuesFile.goalValueId = this.goalValue.id;
      this.goalValue.goalsValuesFiles.push(goalsValuesFile);
    },
    loadGoalValue(id) {
      this.projectService.findGoalValueById(this.callbackFindGoalVAlueById, id);
    },
    callbackFindGoalVAlueById(data) {
      if (data != null) {
        this.goalValue = data;
      }
    },
    copy(goalValue) {
      this.goalValue = new GoalsValues();
      this.goalValue.id = goalValue.id;
      this.goalValue.goalId = goalValue.goalId;
      this.goalValue.order = goalValue.order;
      this.goalValue.value = goalValue.value;
    },
    save() {
      this.projectService.saveGoalValue(this.saveCallback, this.goalValue);
    },
    saveCallback() {
      this.$emit("save");
      this.close();
    },
    removeFile(index) {
      this.goalValue.goalsValuesFiles.splice(index, 1);
    },
    getLastArray(array, n) {
      if (array == null) return void 0;
      if (n == null) return array[array.length - 1];
      return array.slice(Math.max(array.length - n, 0));
    }
  }
};
</script>